<template>
  <div class="md-layout md-alignment-center margin-fix">
    <div class="md-layout-item md-size-80">
      <h2>Países</h2>
      <p>Listado de mercados relacionados a la aplicación</p>
      <md-button @click="showDialog = true">Crear nueva</md-button>
      <md-dialog :md-active.sync="showDialog">
      <md-dialog-title>Nuevo país</md-dialog-title>
      <div class="md-layout md-alignment-center margin-fix">
        <p>Selecciona un país y asígnalo a la aplicación</p>
        <div class="md-layout-item md-size-80">
          <md-field class="input-fix">
            <label for="brand-name">Elige un país</label>
            <md-select name="brand-type" id="brand-type" v-model="country">
              <md-option v-for="country in list" :value="country.id">{{country.code}} - {{country.country}}</md-option>
            </md-select>
          </md-field>
        </div>
      </div>
        <md-dialog-actions class="margin-fix">
          <md-button class="md-primary" @click="onCancel">Cerrar</md-button>
          <md-button class="md-primary" @click="onConfirm">Crear</md-button>
        </md-dialog-actions>
      </md-dialog>
      <div class="md-layout-item">
        <md-table>
          <md-table-row>
            <md-table-head md-numeric>ID relación</md-table-head>
            <md-table-head>País</md-table-head>
            <md-table-head>Acciones</md-table-head>
          </md-table-row>
          <md-table-row v-if="application.length < 1">
            <Empty />
          </md-table-row>
          <md-table-row v-for="(country, index) in application" v-else>
            <md-table-cell md-numeric>{{country.id}}</md-table-cell>
            <md-table-cell>{{country.countries.code}} - {{country.countries.country}}</md-table-cell>
            <md-table-cell>
              <md-button class="md-icon-button md-primary" @click="deleteCountry(country.id)">
                <md-icon>delete</md-icon>
              </md-button>
            </md-table-cell>
          </md-table-row>
        </md-table>
      </div>
    </div>
  </div>
</template>
<script>
import Empty from '@/components/Common/Empty.vue'
import { mapGetters, mapActions } from 'vuex'
export default {
  name: 'Country',
  components: {
    Empty
  },
  data() {
    return {
      showDialog: false,
      country: null
    }
  },
  mounted() {
    this.app(this.$route.params.id)
    this.countries()
  },
  computed: {
    ...mapGetters({application: 'applicationCountries/listApplications', list: 'countries/listCountries'})
  },
  methods: {
    ...mapActions({app: 'applicationCountries/getApplications', countries: 'countries/getCountries',  deleteCountry: 'applicationCountries/deleteApplication'}),
    onConfirm () {
      let data = {'application_id': this.$route.params.id, 'country_id': this.country}
      this.$store.dispatch('applicationCountries/addApplication', data)
      this.showDialog = false
    },
    onCancel () {
      this.showDialog = false
    }
  }
}
</script>

<template>
  <div>
    <Loader class="margin-fix" v-if="loader" />
    <div class="margin-fix" v-else>
      <div class="md-layout md-alignment-center">
        <div class="md-layout-item md-size-80 margin-fix">
          <router-link :to="{name: 'Applications'}" class="margin-fix">
            <md-icon>chevron_left</md-icon> Volver al listado
          </router-link>
        </div>
      </div>
      <div class="md-layout md-alignment-center margin-fix">
        <div class="md-layout-item md-size-80">
          <h2>{{app.description}}</h2>
          <p>Detalles de la aplicación</p>
          <md-table>
            <md-table-row>
              <md-table-head md-numeric>ID</md-table-head>
              <md-table-head>Código de aplicación</md-table-head>
              <md-table-head>Categoría</md-table-head>
            </md-table-row>
            <md-table-row>
              <md-table-cell md-numeric>{{app.id}}</md-table-cell>
              <md-table-cell>{{app.code}}</md-table-cell>
              <md-table-cell>{{app.category.code}} - {{app.category.category}}</md-table-cell>
            </md-table-row>
          </md-table>
        </div>
      </div>
      <References />
      <Brands />
      <Country />
    </div>
  </div>
</template>
<script>
import References from '@/components/Applications/References.vue'
import Country from '@/components/Applications/Country.vue'
import Brands from '@/components/Applications/Brands.vue'
import Loader from '@/components/Common/Loader.vue'
import { mapGetters, mapActions } from 'vuex'
import auth from '@/mixins/auth'
export default {
  name: 'ShowApplication',
  mixins: [auth],
  components: {
    References, Loader, Brands, Country
  },
  data() {
    return {
      loader: true
    }
  },
  mounted() {
    this.application(this.$route.params.id).then(() => {
      this.loader = false
    })
  },
  computed: {
    ...mapGetters({app: 'applications/listApplication'})
  },
  methods: {
    ...mapActions({application: 'applications/getApplication'})
  }
}
</script>

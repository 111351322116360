<template>
  <div class="md-layout md-alignment-center margin-fix">
    <div class="md-layout-item md-size-80">
      <h2>Productos</h2>
      <p>Listado de referencias para el producto</p>
      <md-button @click="showDialog = true">Crear nueva</md-button>
      <md-dialog :md-active.sync="showDialog">
      <md-dialog-title>Nuevo producto</md-dialog-title>
      <div class="md-layout md-alignment-center margin-fix">
        <p>Selecciona un producto y agrégalo a la aplicación</p>
        <div class="md-layout-item md-size-80">
          <md-field class="input-fix">
            <label for="brand-name">Elige un producto</label>
            <md-select name="brand-type" id="brand-type" v-model="product">
              <md-option v-for="product in list" :value="product.id">{{product.sku}}</md-option>
            </md-select>
          </md-field>
        </div>
      </div>
      <md-dialog-actions class="margin-fix">
        <md-button class="md-primary" @click="onCancel">Cerrar</md-button>
        <md-button class="md-primary" @click="onConfirm">Crear</md-button>
      </md-dialog-actions>
      </md-dialog>

      <div class="md-layout-item">
        <md-table>
          <md-table-row>
            <md-table-head md-numeric>ID relación</md-table-head>
            <md-table-head>ID producto</md-table-head>
            <md-table-head>SKU</md-table-head>
            <md-table-head>Acciones</md-table-head>
          </md-table-row>
          <md-table-row v-if="application.length < 1">
            <Empty />
          </md-table-row>
          <md-table-row v-for="(prod, index) in application" v-else>
            <md-table-cell md-numeric>{{prod.id}}</md-table-cell>
            <md-table-cell>{{prod.product.id}}</md-table-cell>
            <md-table-cell>{{prod.product.sku}}</md-table-cell>
            <md-table-cell>
              <md-button class="md-icon-button md-primary" @click="deleteApplication(prod.id)">
                <md-icon>delete</md-icon>
              </md-button>
            </md-table-cell>
          </md-table-row>
        </md-table>
      </div>
    </div>
  </div>
</template>
<script>
import Empty from '@/components/Common/Empty.vue'
import { mapGetters, mapActions } from 'vuex'
export default {
  name: 'References',
  components: {
    Empty
  },
  data() {
    return {
      showDialog: false,
      product: null
    }
  },
  mounted() {
    this.app(this.$route.params.id)
    this.products()
  },
  computed: {
    ...mapGetters({application: 'applicationProducts/listApplications', list: 'products/listProducts'})
  },
  methods: {
    ...mapActions({app: 'applicationProducts/getApplications', products: 'products/getProducts', deleteApplication: 'applicationProducts/deleteApplication'}),
    onConfirm () {
      let data = {'application_id': this.$route.params.id, 'product_id': this.product}
      this.$store.dispatch('applicationProducts/addApplication', data)
      this.showDialog = false
    },
    onCancel () {
      this.showDialog = false
    }
  }
}
</script>
